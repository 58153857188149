
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('../template/', true, /\.js$/));



import "theme";
import "theme.init";
import "jquery.cookieBar";


$.extend(theme.PluginAnimate.defaults, {
	forceAnimation: true
});


$(document).ready(function() {
  console.log('jquery ready template pack');
  //$('.owl-carousel').owlCarousel();

  //$('.owl-section').each(function() {
  //  var id = $(this).attr('id')
  //  $(id).owlCarousel({
  //    items: 1,
  //    loop: true,
  //    center: true,
  //    smartSpeed: 1000,
  //    fluidSpeed: 2000,
  //  });
  //})

  $(document).ready(function() {
    $('.cookie-message').cookieBar({ closeButton : '.my-close-button' });
  });

});


document.addEventListener('turbolinks:load', () => {
  $(".owl-section").owlCarousel({
    items: 1,
    loop: true,
    center: true,
    smartSpeed: 1000,
    fluidSpeed: 2000,
  });

    $(".owl-ilum-product").owlCarousel({
      autoPlay: 400,
      navigation: true,
      slideSpeed: 1600,
      paginationSpeed: 600,
      items: 1,
      loop: true,
      animateIn: "animate__fadeIn",
      animateOut: 'animate__fadeOut',
    });


});
